<template>
    <b-container>
        <!-- DATI FATTURA -->
        <b-row>
            <b-col>
                <b-form-group id="input-group-1" label="Numero fattura:" label-for="input-1" description="Questo valore è il primo numero da utilizzare per la generazione delle fatture, tutte le altre avranno un numero progressivo.">
                    <b-form-input id="input-1" v-model="form.numero_fattura" type="number" required min="1" placeholder="0" :state="validation.numero_fattura()"></b-form-input>
                    <b-form-invalid-feedback :state="validation.numero_fattura()">Il numero fattura deve partire da 1</b-form-invalid-feedback>
                    <b-form-valid-feedback :state="validation.numero_fattura()">Ben fatto.</b-form-valid-feedback>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group id="input-group-2" label="Data documento:" label-for="input-2" description="Data della fattura">
                    <b-form-input id="input-2" v-model="form.data_documento" type="date" required placeholder="GG/MM/AAAA" :state="validation.data_documento()"></b-form-input>
                    <b-form-invalid-feedback :state="validation.data_documento()">Inserire una data valida</b-form-invalid-feedback>
                    <b-form-valid-feedback :state="validation.data_documento()">Ben fatto.</b-form-valid-feedback>
                </b-form-group>
            </b-col>
            <b-col cols="2">
                <b-form-group label="Percentuale IVA:">
                    <b-input-group append="%">
                        <b-form-input id="input-1" v-model="form.perciva" type="number" required min="0" placeholder="22" :state="validation.perciva()"></b-form-input>
                    </b-input-group>
                    <b-form-invalid-feedback :state="validation.perciva()">La percentuale dell'IVA deve essere maggiore o uguale a 0</b-form-invalid-feedback>
                    <b-form-valid-feedback :state="validation.perciva()">Ben fatto.</b-form-valid-feedback>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    label="Causale e articolo fattura:"
                    description="Specificare il nome dell'evento o un riferimento da far comparire all'interno della lista Articoli della fattura e alla fine dell'esempio causale della fattura che vedi di seguito"
                >
                    <b-form-input v-model="form.suffisso_causale" type="text" placeholder="Nome dell'evento"></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group label="Esempio causale:" description="La causale cambia per ogni ordine effettuato e contiene il numero di biglietti acquistati, l'id dell'ordine e il nome specificato sopra">
                    <b-form-input v-model="form.causale_parsed" type="text" readonly></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <!-- MITTENTE -->
        <b-row>
            <b-col>
                <b-card title="Mittente">
                    <b-card-text>
                        <b-form-group label="Ragione sociale:">
                            <b-form-input v-model="form.mittente.ragione_sociale" type="text" required placeholder="Nome azienda srl" :state="validation.ragione_sociale()"></b-form-input>
                            <b-form-invalid-feedback :state="validation.ragione_sociale()">La ragione sociale deve essere almeno di 5 caratteri</b-form-invalid-feedback>
                            <b-form-valid-feedback :state="validation.ragione_sociale()">Ben fatto.</b-form-valid-feedback>
                        </b-form-group>

                        <b-row>
                            <b-col cols="8">
                                <b-form-group label="Via / Piazza:">
                                    <b-form-input v-model="form.mittente.indirizzo" type="text" required placeholder="Indirizzo" :state="validation.indirizzo()"></b-form-input>
                                    <b-form-invalid-feedback :state="validation.indirizzo()">Specificare un indirizzo valido</b-form-invalid-feedback>
                                    <b-form-valid-feedback :state="validation.indirizzo()">Ben fatto.</b-form-valid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Civico:">
                                    <b-form-input v-model="form.mittente.numero_civico" type="text" required placeholder :state="validation.numero_civico()"></b-form-input>
                                    <b-form-invalid-feedback :state="validation.numero_civico()">Specificare un numero civico valido</b-form-invalid-feedback>
                                    <b-form-valid-feedback :state="validation.numero_civico()">Ben fatto.</b-form-valid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <b-form-group label="Comune:">
                                    <b-form-input v-model="form.mittente.comune" type="text" required placeholder :state="validation.comune()"></b-form-input>
                                    <b-form-invalid-feedback :state="validation.comune()">Specificare un comune valido</b-form-invalid-feedback>
                                    <b-form-valid-feedback :state="validation.comune()">Ben fatto.</b-form-valid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="2">
                                <b-form-group label="CAP:">
                                    <b-form-input v-model="form.mittente.cap" type="text" required placeholder="00000" :state="validation.cap()"></b-form-input>
                                    <b-form-invalid-feedback :state="validation.cap()">Specificare un cap valido</b-form-invalid-feedback>
                                    <b-form-valid-feedback :state="validation.cap()">Ben fatto.</b-form-valid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="2">
                                <b-form-group label="Provincia:">
                                    <b-form-input v-model="form.mittente.provincia" type="text" required placeholder="RM" :state="validation.provincia()"></b-form-input>
                                    <b-form-invalid-feedback :state="validation.provincia()">Specificare una provincia valida</b-form-invalid-feedback>
                                    <b-form-valid-feedback :state="validation.provincia()">Ben fatto.</b-form-valid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="2">
                                <b-form-group label="Nazione:">
                                    <b-form-input v-model="form.mittente.nazione" type="text" required placeholder="IT" :state="validation.nazione()"></b-form-input>
                                    <b-form-invalid-feedback :state="validation.nazione()">Specificare una nazione valida</b-form-invalid-feedback>
                                    <b-form-valid-feedback :state="validation.nazione()">Ben fatto.</b-form-valid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="8">
                                <b-form-group label="Indirizzo PEC:">
                                    <b-form-input v-model="form.mittente.pec" type="text" required placeholder="email@pec.it" :state="validation.pec()"></b-form-input>
                                    <b-form-invalid-feedback :state="validation.pec()">Specificare un indirizzo PEC valido</b-form-invalid-feedback>
                                    <b-form-valid-feedback :state="validation.pec()">Ben fatto.</b-form-valid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label="Codice SDI:">
                                    <b-form-input v-model="form.mittente.sdi" type="text" required placeholder="000000" :state="validation.sdi()"></b-form-input>
                                    <b-form-invalid-feedback :state="validation.sdi()">Specificare un codice sdi valido</b-form-invalid-feedback>
                                    <b-form-valid-feedback :state="validation.sdi()">Ben fatto.</b-form-valid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="8">
                                <b-form-group label="Regime Fiscale:">
                                    <b-form-select v-model="form.mittente.regimefisc" :options="regimefisc_options" required :state="validation.regimefisc()"></b-form-select>
                                    <b-form-invalid-feedback :state="validation.regimefisc()">Specificare un regime fiscale</b-form-invalid-feedback>
                                    <b-form-valid-feedback :state="validation.regimefisc()">Ben fatto.</b-form-valid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>

        <!-- DESTINATARIO -->
        <b-row class="mt-3" :hidden="false">
            <b-col>
                <b-card title="Destinatario">
                    <b-card-text>
                        <b-row>
                            <b-col>
                                <b-form-group label="Ragione sociale:">
                                    <b-form-input v-model="form.destinatario.ragione_sociale" type="text" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label="Partita IVA:">
                                    <b-form-input v-model="form.destinatario.ragione_sociale" type="text" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <b-form-group label="Codice Fiscale:">
                                    <b-form-input v-model="form.destinatario.ragione_sociale" type="text" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label="Partita IVA:">
                                    <b-form-input v-model="form.destinatario.ragione_sociale" type="text" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <b-form-group label="Indirizzo:">
                                    <b-form-input v-model="form.destinatario.indirizzo" type="text" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="6">
                                <b-form-group label="Comune:">
                                    <b-form-input v-model="form.destinatario.comune" type="text" readonly placeholder></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="CAP:">
                                    <b-form-input v-model="form.destinatario.cap" type="text" readonly placeholder="00000"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Provincia:">
                                    <b-form-input v-model="form.destinatario.provincia" type="text" readonly placeholder="AB"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Nazione:">
                                    <b-form-input v-model="form.destinatario.nazione" type="text" readonly placeholder="IT"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>

        <b-row class="mt-3">
            <b-col>
                <b-alert variant="info" show>
                    <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;E' consigliato effettuare un test dei dati inseriti, premendo il pulsante verrà generata una sola fattura XML contentente il destinatario selezionato, caricarla
                    nel proprio sistema di fatturazione per verificare che tutti i dati siano corretti e che non manchi nulla.
                </b-alert>
                <b-alert variant="danger" v-if="test_api_error" show>
                    <i class="fas fa-plug"></i>&nbsp;I dati inseriti non risultano validi, controllare nuovamente e riprovare, nel caso in cui il problema non si risolve, contatta il supporto tramite il tasto "Hai bisogno di aiuto?"
                </b-alert>
                <b-button block v-if="!test_api" size="lg" variant="warning" :disabled="test_api_inprogress" @click="testApi">
                    <i v-if="!test_api_inprogress" class="fas fa-plug"></i>
                    <b-spinner v-if="test_api_inprogress" small type="grow"></b-spinner>Scarica una fattura di esempio con i dati inseriti
                </b-button>
                <b-button block v-if="test_api" variant="outline-success" @click="resetTestApi"> <i class="fas fa-check-double"></i> Effettua un nuovo test dei dati inseriti </b-button>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    props: {
        destinatario: Object,
    },
    mounted: function () {
        this.form.numero_fattura = 1;
        this.form.perciva = 22;
        // this.form.mittente = {
        //     ragione_sociale: "Alpaca Team srl",
        //     piva: "01234567890",
        //     indirizzo: "viale Antonio Gramsci",
        //     numero_civico: "85",
        //     comune: "Montoro",
        //     provincia: "AV",
        //     cap: "83025",
        //     nazione: "IT",
        //     pec: "alpacateam@pec.it",
        //     sdi: "M5UXCR1",
        //     regimefisc: "RF01",
        // };
        // console.warn("RIMUOVERE DATI DI TEST");

        // Aggiungere la data di oggi
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();

        // today = dd + "/" + mm + "/" + yyyy;
        today = yyyy + "-" + mm + "-" + dd;
        this.form.data_documento = today;
    },
    data() {
        return {
            form_validated: false,
            test_api: false,
            test_api_inprogress: false,
            test_api_error: false,
            form: {
                numero_fattura: null,
                data_documento: null,
                causale: "",
                causale_parsed: "",
                suffisso_causale: "",
                perciva: null,
                mittente: {
                    ragione_sociale: null,
                    piva: null,
                    indirizzo: null,
                    numero_civico: null,
                    comune: null,
                    provincia: null,
                    cap: null,
                    nazione: "IT",
                    pec: null,
                    sdi: null,
                    regimefisc: null,
                },
                destinatario: {
                    ragione_sociale: "",
                    indirizzo: "",
                    comune: "",
                    provincia: "",
                    cap: "",
                    nazione: "",
                    piva: "",
                    codfisc: "",
                },
            },
            regimefisc_options: [
                { value: "RF01", text: "RF01 = Regime ordinario" },
                {
                    value: "RF02",
                    text: "RF02 = Regime dei contribuenti minimi (art. 1,c.96-117, L. 244/2007)",
                },
                {
                    value: "RF04",
                    text: "RF04 = Agricoltura e attività connesse e pesca (artt. 34 e 34-bis, D.P.R. 633/1972)",
                },
                {
                    value: "RF05",
                    text: "RF05 = Vendita sali e tabacchi (art. 74, c.1, D.P.R. 633/1972)",
                },
                {
                    value: "RF06",
                    text: "RF06 = Commercio dei fiammiferi (art. 74, c.1, D.P.R. 633/1972)",
                },
                {
                    value: "RF07",
                    text: "RF07 = Editoria (art. 74, c.1, D.P.R. 633/1972)",
                },
                {
                    value: "RF08",
                    text: "RF08 = Gestione di servizi di telefonia pubblica (art. 74, c.1, D.P.R. 633/1972)",
                },
                {
                    value: "RF09",
                    text: "RF09 = Rivendita di documenti di trasporto pubblico e di sosta (art. 74, c.1, D.P.R. 633/1972)",
                },
                {
                    value: "RF10",
                    text: "RF10 = Intrattenimenti, giochi e altre attività di cui alla tariffa allegata al D.P.R. 640/72 (art. 74, c.6, D.P.R. 633/1972)",
                },
                {
                    value: "RF11",
                    text: "RF11 = Agenzie di viaggi e turismo (art. 74-ter, D.P.R. 633/1972)",
                },
                {
                    value: "RF12",
                    text: "RF12 = Agriturismo (art. 5, c.2, L. 413/1991)",
                },
                {
                    value: "RF13",
                    text: "RF13 = Vendite a domicilio (art. 25-bis, c.6, D.P.R. 600/1973)",
                },
                {
                    value: "RF14",
                    text: "RF14 = Rivendita di beni usati, di oggetti d’arte, d’antiquariato o da collezione (art. 36, D.L. 41/1995)",
                },
                {
                    value: "RF15",
                    text: "RF15 = Agenzie di vendite all’asta di oggetti d’arte, antiquariato o da collezione (art. 40-bis, D.L. 41/1995)",
                },
                {
                    value: "RF16",
                    text: "RF16 = IVA per cassa P.A. (art. 6, c.5, D.P.R. 633/1972)",
                },
                {
                    value: "RF17",
                    text: "RF17 = IVA per cassa (art. 32-bis, D.L. 83/2012)",
                },
                { value: "RF19", text: "RF19 = Regime forfettario" },
                { value: "RF18", text: "RF18 = Altro" },
            ],
        };
    },
    methods: {
        resetTestApi: function (event) {
            this.test_api = false;
            //   this.form.cod_iva = "0";
            //   this.form.conto_saldo = null;
            this.test_api_error = false;
        },
        testApi: function (event) {
            let self = this;
            this.test_api_error = false;
            this.test_api_inprogress = true;

            let request_data = form;

            let url = "/api/fatturapa/testdata";
            window.axios
                .post(url, request_data, { crossdomain: true })
                .then(function (response) {
                    self.test_api_inprogress = false;
                    if (response.data) {
                        if (response.data.error) {
                            self.test_api = false;
                            self.test_api_error = true;
                            return;
                        }

                        // if (response.data.lista_iva) {
                        //   self.cod_iva_options = [];

                        //   response.data.lista_iva.forEach(element => {
                        //     self.cod_iva_options.push({
                        //       value: element.cod_iva,
                        //       text: element.valore_iva + "% " + element.descrizione_iva
                        //     });
                        //   });
                        // }

                        // if (response.data.lista_conti) {
                        //   self.conto_saldo_options = [];

                        //   response.data.lista_conti.forEach(element => {
                        //     self.conto_saldo_options.push({
                        //       value: element.nome_conto,
                        //       text: element.nome_conto
                        //     });
                        //   });
                        // }

                        self.test_api = true;
                    } else {
                        self.test_api = false;
                    }
                })
                .catch(function (error) {
                    self.test_api_inprogress = false;
                    self.test_api = false;
                    self.test_api_error = true;
                });
        },
    },
    computed: {
        validation: function () {
            let self = this;
            return {
                numero_fattura: function () {
                    return self.form.numero_fattura && self.form.numero_fattura >= 1;
                },
                data_documento: function () {
                    let d = Date.parse(self.form.data_documento);
                    return self.form.data_documento && !isNaN(d);
                },
                ragione_sociale: function () {
                    return self.form.mittente.ragione_sociale && self.form.mittente.ragione_sociale.length > 4;
                },
                indirizzo: function () {
                    return self.form.mittente.indirizzo && self.form.mittente.indirizzo.length > 4;
                },
                numero_civico: function () {
                    return self.form.mittente.numero_civico && self.form.mittente.numero_civico.length >= 1;
                },
                comune: function () {
                    return self.form.mittente.comune && self.form.mittente.comune.length > 3;
                },
                provincia: function () {
                    return self.form.mittente.provincia && self.form.mittente.provincia.length == 2;
                },
                cap: function () {
                    return self.form.mittente.cap && self.form.mittente.cap.length >= 5;
                },
                nazione: function () {
                    return self.form.mittente.nazione && self.form.mittente.nazione.length >= 2;
                },
                pec: function () {
                    return self.form.mittente.pec && self.form.mittente.pec.length >= 2;
                },
                sdi: function () {
                    return self.form.mittente.sdi && self.form.mittente.sdi.length >= 2;
                },
                regimefisc: function () {
                    return self.form.mittente.regimefisc && self.form.mittente.regimefisc.length >= 3;
                },
                perciva: function () {
                    return self.form.perciva && self.form.perciva >= 1;
                },
            };
        },
    },
    watch: {
        destinatario: {
            handler: function (val, oldVal) {
                if (val) {
                    this.form.causale = val.causale;
                    this.form.causale_parsed = this.form.causale + " " + this.form.suffisso_causale;
                    this.form.destinatario = val;
                }
            },
            deep: true,
        },
        form: {
            handler: function (val, oldVal) {
                this.form.causale_parsed = this.form.causale + " " + this.form.suffisso_causale;

                for (let c in this.validation) {
                    if (!this.validation[c]()) {
                        this.$emit("data-valid", null);
                        this.form_validated = false;
                        return false;
                    }
                }
                this.$emit("data-valid", val);
                this.form_validated = false;
                return true;
            },
            deep: true,
        },
    },
};
</script>

<style>
</style>
