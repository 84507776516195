<template>
  <b-container>
    <b-card-group deck v-if="destinatari">
      <b-card
        v-if="destinatari_validi > 0"
        border-variant="success"
        header="FattureInCloud.it"
        header-bg-variant="success"
        header-text-variant="white"
        align="center"
      >
        <b-card-text v-if="!fatture_inviate">
          Sono disponibili
          <b>{{ destinatari_validi }}</b>
          destinatari validi su {{ destinatari.length }} per generare le fatture
        </b-card-text>

        <b-button
          v-if="!fatture_inviate && !send_in_progress && !test_completato"
          @click="inviaFattureTest"
          block
          size="lg"
          variant="warning"
        >
          <i class="far fa-paper-plane"></i>
          Effettua un test di invio delle {{ destinatari_validi }} fatture
        </b-button>

        <b-alert show variant="success" v-if="!fatture_inviate && !send_in_progress && test_completato">
          Il test è stato completato con successo, ora puoi inviare le fatture, verranno visualizzate su FattureInCloud.it entro
          <b>{{ minuti_completamento }} minuti</b>
        </b-alert>

        <b-button
          v-if="!fatture_inviate && !send_in_progress && test_completato"
          @click="inviaFatture"
          block
          size="lg"
          variant="primary"
        >
          <i class="far fa-paper-plane"></i>
          Invia {{ destinatari_validi }} fatture
        </b-button>

        <b-button v-if="send_in_progress" variant="primary" block size="lg" disabled>
          <b-spinner small type="grow"></b-spinner>
          Invio {{ destinatari_validi }} fatture in corso...
        </b-button>

        <b-card-text class="mt-3" v-if="fatture_inviate && !send_in_progress">
          <i class="fas fa-business-time my-3 text-success" style="font-size: 50px;"></i>
          <br />
          <b-alert show variant="success">
            Tra
            <b>{{ minuti_completamento }} minuti</b> le fatture saranno visibili su FattureInCloud.it.
          </b-alert>
          <p
            style="font-size: 1.5em;"
          >Se pensi che questo progetto ti abbia migliorato la vita o il tuo lavoro, premi su questo pulsante</p>
          <b-button
            block
            href="https://buy.stripe.com/fZe6ssc9X8HTcRG288"
            target="_blank"
            variant="success"
            size="lg"
          >
            <i class="fas fa-hand-holding-heart"></i> Ringrazia Enrico con una 🍕
          </b-button>
        </b-card-text>

        <b-card-text class="mt-3" v-if="!fatture_inviate && !send_in_progress && send_error">
          <i class="fas fa-bomb my-3 text-danger" style="font-size: 50px;"></i>
          <br />
          <b-alert show variant="danger">
            <i class="fas fa-exclamation-triangle"></i>&nbsp;Si è verificato un errore nell'invio delle fatture, controlla i dati inseriti e riprova. Se hai bisogno di aiuto, usa il tasto in basso a destra per inviare una segnalazione.
          </b-alert>
        </b-card-text>
      </b-card>

      <b-card
        v-if="destinatari_nonvalidi > 0"
        border-variant="danger"
        header="Attenzione"
        header-bg-variant="danger"
        header-text-variant="white"
        align="center"
      >
        <b-card-text>Alcuni destinatari presentano degli errori all'interno dei dati, per questi non sarà possibile generare la fattura.</b-card-text>
        <b-card-text class="text-danger font-weight-bold">
          <i class="fas fa-exclamation-triangle"></i>
          {{ destinatari_nonvalidi }} destinatari non validi
        </b-card-text>
        <b-card-text align="left">
          <b-list-group>
            <b-list-group-item
              v-for=" (e, index) in destinatari_errori"
              :key="index"
              class="d-flex justify-content-between align-items-center"
            >
              <small>{{ e.msg }}</small>
              <b-badge variant="danger" pill>{{ e.count }}</b-badge>
            </b-list-group-item>

            <b-list-group-item
              v-for=" (e, index) in destinatari_warning"
              :key="index"
              class="d-flex justify-content-between align-items-center"
            >
              <small>{{ e.msg }}</small>
              <b-badge variant="warning" pill>{{ e.count }}</b-badge>
            </b-list-group-item>
          </b-list-group>
        </b-card-text>
      </b-card>
    </b-card-group>
  </b-container>
</template>

<script>
export default {
  props: {
    dati_fattura: Object,
    destinatari: Array
  },
  data() {
    return {
      form_validated: false,
      form: {},
      destinatari_errori: {},
      destinatari_warning: {},
      destinatari_validi: 0,
      destinatari_nonvalidi: 0,
      dati_destinatari_validi: [],
      fatture_inviate: false,
      send_in_progress: false,
      send_error: false,
      minuti_completamento: 0,
      destinatari_validi_inviati: 0,
      test_completato: false
    };
  },
  methods: {
    inviaFatture: function(event) {
      this.send_in_progress = true;
      this.send_error = false;

      let request_data = {
        api_uid: this.dati_fattura.api_uid,
        api_key: this.dati_fattura.api_key,
        dati_fattura: this.dati_fattura,
        dati_destinatari_validi: this.dati_destinatari_validi
      };

      let self = this;
      window.axios
        .post("/api/invoices/schedule", request_data)
        .then(res => {
          let data = res.data;

          self.minuti_completamento = data.total_min_to_complete;
          self.destinatari_validi_inviati = data.counter;

          self.send_in_progress = false;
          self.fatture_inviate = true;
          self.send_error = false;
        })
        .catch(function(error) {
          // handle error
          self.send_in_progress = false;
          self.fatture_inviate = false;
          self.send_error = true;
        });
    },
    inviaFattureTest: function(event) {
      this.send_in_progress = true;
      this.send_error = false;

      let request_data = {
        test: true,
        api_uid: this.dati_fattura.api_uid,
        api_key: this.dati_fattura.api_key,
        dati_fattura: this.dati_fattura,
        dati_destinatari_validi: this.dati_destinatari_validi
      };

      let self = this;
      window.axios
        .post("/api/invoices/schedule", request_data)
        .then(res => {
          let data = res.data;

          self.minuti_completamento = data.total_min_to_complete;
          self.destinatari_validi_inviati = data.counter;

          self.send_in_progress = false;
          self.test_completato = true;
          self.send_error = false;
        })
        .catch(function(error) {
          // handle error
          self.send_in_progress = false;
          self.test_completato = false;
          self.send_error = true;
        });
    }
  },
  computed: {
    validation: function() {
      let self = this;
      return {};
    }
  },
  watch: {
    destinatari: {
      handler: function(val, oldVal) {
        this.dati_destinatari_validi = [];
        this.destinatari_validi = 0;
        this.destinatari_nonvalidi = 0;
        this.destinatari_errori = {};
        this.destinatari_warning = {};

        for (let d in val) {
          let err = val[d].errori_fatturazione;
          for (let j in err) {
            if (!this.destinatari_errori[err[j].field]) {
              this.destinatari_errori[err[j].field] = {
                count: 0,
                msg: err[j].msg
              };
            }
            this.destinatari_errori[err[j].field].count++;
          }

          let warn = val[d].warning;
          for (let j in warn) {
            if (!this.destinatari_warning[warn[j].field]) {
              this.destinatari_warning[warn[j].field] = {
                count: 0,
                msg: warn[j].msg
              };
            }
            this.destinatari_warning[warn[j].field].count++;
          }

          if (err && err.length > 0) {
            this.destinatari_nonvalidi++;
          } else {
            this.dati_destinatari_validi.push(val[d]);
            this.destinatari_validi++;
          }
        }
      },
      deep: true
    }
  }
};
</script>

<style>
</style>
