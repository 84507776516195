<template>
  <b-container>
    <h2>
      <i class="fas fa-history"></i> Crea fatture ricorrenti
      <span class="float-right">
        <guida-wizard-creazione-fatture-ricorrenti></guida-wizard-creazione-fatture-ricorrenti>
      </span>
    </h2>

    <b-row v-if="!hide_form_api_key" class="mt-3">
      <b-col>
        <b-alert variant="info" show>
          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;L'API UID e l'API Key, sono due codici che identificano in maniera univoca il tuo account su FattureInCloud e consentono a FatturaElettronicaPro di caricare le fatture. Per approfondimenti e video tutorial leggere: [Link all'articolo]
          <br />
          <b-button
            class="mt-2"
            block
            variant="outline-info"
            size="sm"
            href="https://secure.fattureincloud.it/api"
            target="_blank"
          >
            <i class="fas fa-external-link-alt"></i> Vai su FattureInCloud.it e copia i tuoi codici API UID e API Key
          </b-button>
        </b-alert>
      </b-col>
    </b-row>

    <!--
    ....###....########..####....##....##.########.##....##
    ...##.##...##.....##..##.....##...##..##........##..##.
    ..##...##..##.....##..##.....##..##...##.........####..
    .##.....##.########...##.....#####....######......##...
    .#########.##.........##.....##..##...##..........##...
    .##.....##.##.........##.....##...##..##..........##...
    .##.....##.##........####....##....##.########....##...-->

    <b-row v-if="!hide_form_api_key">
      <b-col>
        <b-form-group
          id="input-group-fic-1"
          label="API UID:"
          label-for="input-fic-1"
          description="E' possibile ottenere il proprio API UID sul sito di FattureInCloud.it nella sezione </> API nella barra laterale"
          :state="validation.api_uid()"
        >
          <b-form-input
            id="input-fic-1"
            v-model="form.api_uid"
            type="text"
            required
            min="1"
            placeholder
            :state="validation.api_uid()"
            :readonly="test_api"
          ></b-form-input>
          <b-form-invalid-feedback
            :state="validation.api_uid()"
          >L'API UID è obbligatorio per poter inviare le fatture</b-form-invalid-feedback>
          <b-form-valid-feedback :state="validation.api_uid()">Ben fatto.</b-form-valid-feedback>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="input-group-fic-2"
          label="API Key:"
          label-for="input-fic-2"
          description="E' possibile ottenere la propria API KEY sul sito di FattureInCloud.it nella sezione </> API nella barra laterale"
          :state="validation.api_key()"
        >
          <b-form-input
            id="input-fic-2"
            v-model="form.api_key"
            type="text"
            required
            min="10"
            placeholder
            :state="validation.api_key()"
            :readonly="test_api"
          ></b-form-input>
          <b-form-invalid-feedback
            :state="validation.api_key()"
          >L'API KEY è obbligatoria per poter inviare le fatture</b-form-invalid-feedback>
          <b-form-valid-feedback :state="validation.api_key()">Ben fatto.</b-form-valid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="!hide_form_api_key" class="mb-3">
      <b-col>
        <b-alert variant="info" show>
          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;E' necessario effettuare un test dei dati inseriti per programmare correttamente l'invio delle fatture, questo permetterà anche di recuperare i dati per il Codice IVA e il Conto di Saldo da utilizzare in fattura.
        </b-alert>
        <b-alert variant="danger" v-if="test_api_error" show>
          <i class="fas fa-plug"></i>&nbsp;I dati inseriti come API UIS e API KEY non risultano validi, controllare nuovamente i dati inseriti e riprovare, nel caso in cui il problema non si risolve, contatta il supporto tramite il tasto "Hai bisogno di aiuto?"
        </b-alert>
        <b-button
          block
          v-if="!test_api"
          size="lg"
          variant="warning"
          :disabled="!(validation.api_key() && validation.api_uid()) || test_api_inprogress"
          @click="testApi"
        >
          <i v-if="!test_api_inprogress" class="fas fa-plug"></i>
          <b-spinner v-if="test_api_inprogress" small type="grow"></b-spinner>Effettua un test dei dati API UID e API Key
        </b-button>
        <b-button block v-if="test_api" variant="outline-success" @click="resetTestApi">
          <i class="fas fa-check-double"></i> Effettua di nuovo il test dei dati API UID e API Key
        </b-button>
      </b-col>
    </b-row>
    <!--
    ..######..########.########...######.....###.......########....###....########.########.##.....##.########..########
    .##....##.##.......##.....##.##....##...##.##......##.........##.##......##.......##....##.....##.##.....##.##......
    .##.......##.......##.....##.##........##...##.....##........##...##.....##.......##....##.....##.##.....##.##......
    .##.......######...########..##.......##.....##....######...##.....##....##.......##....##.....##.########..######..
    .##.......##.......##...##...##.......#########....##.......#########....##.......##....##.....##.##...##...##......
    .##....##.##.......##....##..##....##.##.....##....##.......##.....##....##.......##....##.....##.##....##..##......
    ..######..########.##.....##..######..##.....##....##.......##.....##....##.......##.....#######..##.....##.########-->

    <b-row v-if="test_api">
      <b-col>
        <b-form-group
          id="input-group-cerca-fatture-1"
          label="Anno:"
          label-for="input-cerca-fatture-1"
          description="Scegli l'anno nel quale cercare le fatture"
          :state="validation.anno_cerca_fatture()"
        >
          <b-form-input
            id="input-cerca-fatture-1"
            v-model="form.anno_cerca_fatture"
            type="text"
            required
            :state="validation.anno_cerca_fatture()"
          ></b-form-input>
          <b-form-invalid-feedback
            :state="validation.anno_cerca_fatture()"
          >L'anno è obbligatorio per poter cercare le fatture</b-form-invalid-feedback>
          <b-form-valid-feedback :state="validation.anno_cerca_fatture()">Ben fatto.</b-form-valid-feedback>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="input-group-cerca-fatture-2"
          label="Data inizio ricerca:"
          label-for="input-cerca-fatture-2"
          description="Scegliere una data per ricercare la fattura da rendere ricorrente, precedentemente creata su FattureInCloud"
          :state="validation.data_inizio_cerca_fatture()"
        >
          <b-form-input
            id="input-cerca-fatture-2"
            v-model="form.data_inizio_cerca_fatture"
            type="date"
            required
            placeholder
            :state="validation.data_inizio_cerca_fatture()"
          ></b-form-input>
          <b-form-invalid-feedback
            :state="validation.data_inizio_cerca_fatture()"
          >La data è obbligatoria per poter cercare le fatture</b-form-invalid-feedback>
          <b-form-valid-feedback :state="validation.data_inizio_cerca_fatture()">Ben fatto.</b-form-valid-feedback>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="input-group-cerca-fatture-3"
          label="Data fine ricerca:"
          label-for="input-cerca-fatture-3"
          description="Scegliere una data per ricercare la fattura da rendere ricorrente, precedentemente creata su FattureInCloud"
          :state="validation.data_fine_cerca_fatture()"
        >
          <b-form-input
            id="input-cerca-fatture-3"
            v-model="form.data_fine_cerca_fatture"
            type="date"
            required
            placeholder
            :state="validation.data_fine_cerca_fatture()"
          ></b-form-input>
          <b-form-invalid-feedback
            :state="validation.data_fine_cerca_fatture()"
          >La data è obbligatoria per poter cercare le fatture</b-form-invalid-feedback>
          <b-form-valid-feedback :state="validation.data_fine_cerca_fatture()">Ben fatto.</b-form-valid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mb-3" v-if="test_api">
      <b-col>
        <b-button
          block
          v-if="test_api"
          size="lg"
          variant="primary"
          :disabled="!(validation.anno_cerca_fatture() && validation.data_inizio_cerca_fatture() && validation.data_fine_cerca_fatture()) || step1_cercaFatture_in_progress"
          @click="step1CercaFatture"
        >
          <i v-if="!step1_cercaFatture_in_progress" class="fas fa-search"></i>
          <b-spinner v-if="step1_cercaFatture_in_progress" small type="grow"></b-spinner>Cerca le fatture
        </b-button>
      </b-col>
    </b-row>

    <b-row class="mb-3" v-if="test_api && lista_documenti.length > 0">
      <b-col>
        <b-form-group
          id="input-group-seleziona-fattura-1"
          label="Fatture trovate:"
          label-for="input-seleziona-fattura-1"
          description="Selezionare la fattura da rendere ricorrente, in basso è disponibile l'anteprima"
          :state="validation.data_fine_cerca_fatture()"
        >
          <b-form-select
            id="input-seleziona-fattura-1"
            v-model="lista_documenti_selected"
            :options="lista_documenti"
            value-field="id"
            text-field="nome"
            @change="step2CercaFattura"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row
      class="mb-3"
      v-if="documento_selezionato && ((dettagli_documento_selezionato &&  documento_selezionato.numero != dettagli_documento_selezionato.numero) || !dettagli_documento_selezionato)"
    >
      <b-col>
        <b-card
          :header="'Fattura numero: '+documento_selezionato.numero"
          header-tag="header"
          :footer="'Importo totale: '+documento_selezionato.importo_totale+' '+documento_selezionato.valuta"
          footer-tag="footer"
          :title="documento_selezionato.nome"
        >
          <b-card-text>Fattura del {{documento_selezionato.data}} numero {{documento_selezionato.numero}}</b-card-text>
          <b-button :href="documento_selezionato.link_doc" target="_blank" variant="primary">
            <i class="far fa-file-pdf"></i> PDF della fattura
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <!--
    .########..########.########.########....###.....######...##.......####....########....###....########.########.##.....##.########.....###...
    .##.....##.##..........##.......##......##.##...##....##..##........##.....##.........##.##......##.......##....##.....##.##.....##...##.##..
    .##.....##.##..........##.......##.....##...##..##........##........##.....##........##...##.....##.......##....##.....##.##.....##..##...##.
    .##.....##.######......##.......##....##.....##.##...####.##........##.....######...##.....##....##.......##....##.....##.########..##.....##
    .##.....##.##..........##.......##....#########.##....##..##........##.....##.......#########....##.......##....##.....##.##...##...#########
    .##.....##.##..........##.......##....##.....##.##....##..##........##.....##.......##.....##....##.......##....##.....##.##....##..##.....##
    .########..########....##.......##....##.....##..######...########.####....##.......##.....##....##.......##.....#######..##.....##.##.....##-->

    <b-row
      class="mb-3"
      v-if="documento_selezionato && ((dettagli_documento_selezionato &&  documento_selezionato.numero != dettagli_documento_selezionato.numero) || !dettagli_documento_selezionato)"
    >
      <b-col>
        <b-button
          block
          v-if="true || documento_selezionato"
          size="lg"
          variant="primary"
          @click="step3DettagliFattura"
        >
          <i v-if="!step3_dettagliFattura_in_progress" class="fas fa-info-circle"></i>
          <b-spinner v-if="step3_dettagliFattura_in_progress" small type="grow"></b-spinner>Scarica i dettagli della fattura per poi renderla ricorrente
        </b-button>
      </b-col>
    </b-row>

    <b-row
      class="mb-3"
      v-if="dettagli_documento_selezionato && documento_selezionato.numero == dettagli_documento_selezionato.numero"
    >
      <b-col>
        <b-card
          :header="'Dettagli Fattura numero: '+dettagli_documento_selezionato.numero"
          header-tag="header"
          :footer="'Importo totale: '+dettagli_documento_selezionato.importo_totale+' '+dettagli_documento_selezionato.valuta"
          footer-tag="footer"
          :title="dettagli_documento_selezionato.nome"
        >
          <b-card-text>
            <b-alert variant="info" show>
              <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;Puoi rendere variabili i nomi dei prodotti in base al periodo di fatturazione, ad esempio puoi usare !!inizio_mese!! o !!fine_mese!!, queste parole verranno sostituite con le date corrispondenti. [vedi l'elenco di tutte le variabili che si possono utilizzare]
            </b-alert>
            <br />
            <b>Lista Articoli</b>
            <br />

            <b-row>
              <b-col>
                <b-form-group
                  v-for="articolo in dettagli_documento_selezionato.lista_articoli"
                  v-bind:key="articolo.id"
                  id="input-group-dettagli-fattura-1"
                  label="Nome prodotto"
                  label-for="input-dettagli-fattura-1"
                  :description="articolo.desc_iva"
                >
                  <b-form-input
                    id="input-dettagli-fattura-1"
                    v-model="articolo.nome"
                    type="text"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-fic-3"
                  label="Codice IVA:"
                  label-for="input-fic-3"
                  description="E' possibile visualizzare tutte le aliquote sul sito di FattureInCloud.it nel menu Impostazioni > Aliquote IVA"
                >
                  <b-form-select
                    v-for="articolo in dettagli_documento_selezionato.lista_articoli"
                    v-bind:key="articolo.id"
                    id="input-fic-3"
                    v-model="articolo.cod_iva"
                    :options="cod_iva_options"
                    :disabled="!(validation.api_key() && validation.api_uid())"
                    required
                  >
                    <template slot="first">
                      <option
                        :value="null"
                        disabled
                      >-- Seleziona il valore IVA corrispondente alla fattura --</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <!--
    .########..####..######...#######..########..########..########.##....##.########....###...
    .##.....##..##..##....##.##.....##.##.....##.##.....##.##.......###...##......##....##.##..
    .##.....##..##..##.......##.....##.##.....##.##.....##.##.......####..##.....##....##...##.
    .########...##..##.......##.....##.########..########..######...##.##.##....##....##.....##
    .##...##....##..##.......##.....##.##...##...##...##...##.......##..####...##.....#########
    .##....##...##..##....##.##.....##.##....##..##....##..##.......##...###..##......##.....##
    .##.....##.####..######...#######..##.....##.##.....##.########.##....##.########.##.....##
    -->

    <b-row
      class="mb-3"
      v-if="dettagli_documento_selezionato && documento_selezionato.numero == dettagli_documento_selezionato.numero"
    >
      <b-col>
        <b-card header="Ricorrenza" header-tag="header" title="Scegli il periodo di ricorrenza">
          <b-card-text>
            <b-row v-if="form.ricorrenza_start_value">
              <b-col cols="4">
                <b-form-group
                  id="input-group-date-start-ricorrenza"
                  label="Data inizio ricorrenza"
                  label-for="input-date-start-ricorrenza"
                >
                  <b-form-input
                    required
                    v-model="form.ricorrenza_start_value"
                    type="date"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-alert variant="info" show>
              <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;Scegli ogni quanti giorni/settimane/mesi/anni bisogna ripetere la fattura.
            </b-alert>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-ricorrenza-1"
                  label="Ripeti ogni"
                  label-for="input-ricorrenza-1"
                >
                  <b-form-input
                    id="input-ricorrenza-1"
                    v-model="form.ricorrenza_value"
                    type="number"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-ricorrenza-2"
                  label="Periodo"
                  label-for="input-ricorrenza-2"
                >
                  <b-form-select
                    id="input-ricorrenza-2"
                    v-model="form.ricorrenza_type"
                    class="mb-3"
                    required
                  >
                    <b-form-select-option value="d">Giorno</b-form-select-option>
                    <b-form-select-option value="w">Settimana</b-form-select-option>
                    <b-form-select-option value="M">Mese</b-form-select-option>
                    <b-form-select-option value="y">Anno</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Fine ricorrenza">
                  <b-form-radio
                    v-model="form.ricorrenza_end_type"
                    name="ricorrenza_end_type"
                    value="never"
                  >Mai</b-form-radio>
                  <b-form-radio
                    v-model="form.ricorrenza_end_type"
                    name="ricorrenza_end_type"
                    value="date"
                  >
                    <b-form inline>
                      Data&nbsp;
                      <b-form-input
                        required
                        v-if="form.ricorrenza_end_type == 'date'"
                        size="sm"
                        v-model="form.ricorrenza_end_value"
                        type="date"
                      ></b-form-input>
                    </b-form>
                  </b-form-radio>
                  <b-form-radio
                    v-model="form.ricorrenza_end_type"
                    name="ricorrenza_end_type"
                    value="occurrences"
                  >
                    <b-form inline>
                      Dopo&nbsp;
                      <b-form-input
                        required
                        v-if="form.ricorrenza_end_type == 'occurrences'"
                        size="sm"
                        v-model="form.ricorrenza_end_value"
                        type="number"
                      ></b-form-input>&nbsp;occorrenze
                    </b-form>
                  </b-form-radio>
                </b-form-group>
              </b-col>
            </b-row>
            <b-alert variant="warning" show>
              <i class="fa fa-history" aria-hidden="true"></i>
              &nbsp;La prossima fattura verrà emessa il
              <b>{{ ricorrenzaProssimaFattura }}</b>
            </b-alert>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row
      class="mb-3"
      v-if="dettagli_documento_selezionato && documento_selezionato.numero == dettagli_documento_selezionato.numero"
    >
      <b-col>
        <b-button
          v-if="!step4_inviaFattura_completed || step4_inviaFattura_error"
          block
          size="lg"
          variant="primary"
          @click="step4InviaFattura"
        >
          <i v-if="!step4_inviaFattura_in_progress" class="fas fa-send"></i>
          <b-spinner v-if="step4_inviaFattura_in_progress" small type="grow"></b-spinner>Rendi la fattura ricorrente
        </b-button>
        <b-alert v-if="step4_inviaFattura_completed" variant="success" show>
          <i class="fa fa-history" aria-hidden="true"></i>
          &nbsp;La fattura è stata programmata correttamente
        </b-alert>
        <b-alert v-if="step4_inviaFattura_error" variant="danger" show>
          <i class="fa fa-history" aria-hidden="true"></i>
          &nbsp;Si è verificato un errore nell'invio della fattura, riprovare o contattare l'assistenza
        </b-alert>
      </b-col>
    </b-row>

    <br />
    <br />
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      form_validated: false,
      test_api: false,
      hide_form_api_key: false,
      test_api_inprogress: false,
      step1_cercaFatture_in_progress: false,
      step3_dettagliFattura_in_progress: false,
      step4_inviaFattura_in_progress: false,
      step4_inviaFattura_completed: false,
      step4_inviaFattura_error: false,
      test_api_error: false,
      lista_documenti: new Array(),
      lista_documenti_selected: null,
      documento_selezionato: null,
      dettagli_documento_selezionato: null,
      cod_iva_options: null,
      conto_saldo_options: null,
      form: {
        api_uid: null,
        api_key: null,
        anno_cerca_fatture: null,
        data_inizio_cerca_fatture: null,
        data_fine_cerca_fatture: null,
        ricorrenza_value: 1,
        ricorrenza_type: "M",
        ricorrenza_end_type: "never",
        ricorrenza_end_value: null,
        ricorrenza_start_value: null
      }
    };
  },
  mounted: function() {
    // Aggiungere la data di oggi
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    // today = dd + "/" + mm + "/" + yyyy;
    let dataOggi = yyyy + "-" + mm + "-" + dd;
    let inizioMese = yyyy + "-" + mm + "-01";
    this.form.anno_cerca_fatture = "" + yyyy;
    this.form.data_inizio_cerca_fatture = inizioMese;
    this.form.data_fine_cerca_fatture = dataOggi;

    if (fep && fep.settings) {
      this.form.api_uid =
        fep.settings.fic_api_uid.length > 0 ? fep.settings.fic_api_uid : null;
      this.form.api_key =
        fep.settings.fic_api_key.length > 0 ? fep.settings.fic_api_key : null;

      if (
        fep.settings.fic_api_uid.length > 0 &&
        fep.settings.fic_api_key.length > 0
      ) {
        // this.testApi(null);
      }
    }
  },
  methods: {
    resetTestApi: function(event) {
      this.test_api = false;
      this.test_api_error = false;
    },
    testApi: function(event) {
      let self = this;
      this.test_api_error = false;
      this.test_api_inprogress = true;

      let request_data = {
        api_uid: this.form.api_uid,
        api_key: this.form.api_key,
        campi: ["nome", "lista_iva", "lista_conti"]
      };

      let url = "/api/invoices/testficapidata";
      window.axios
        .post(url, request_data, { crossdomain: true })
        .then(function(response) {
          self.test_api_inprogress = false;
          if (response.data) {
            if (response.data.error) {
              self.test_api = false;
              self.test_api_error = true;
              return;
            }

            if (response.data.lista_iva) {
              self.cod_iva_options = [];

              response.data.lista_iva.forEach(element => {
                self.cod_iva_options.push({
                  value: element.cod_iva,
                  text: element.valore_iva + "% " + element.descrizione_iva
                });
              });
            }

            if (response.data.lista_conti) {
              self.conto_saldo_options = [];

              response.data.lista_conti.forEach(element => {
                self.conto_saldo_options.push({
                  value: element.nome_conto,
                  text: element.nome_conto
                });
              });
            }

            self.test_api = true;
            self.hide_form_api_key = true;
          } else {
            self.test_api = false;
          }
        })
        .catch(function(error) {
          self.test_api_inprogress = false;
          self.test_api = false;
          self.test_api_error = true;
        });
    },

    // ..######..########.########...######.....###.......########....###....########.########.##.....##.########..########
    // .##....##.##.......##.....##.##....##...##.##......##.........##.##......##.......##....##.....##.##.....##.##......
    // .##.......##.......##.....##.##........##...##.....##........##...##.....##.......##....##.....##.##.....##.##......
    // .##.......######...########..##.......##.....##....######...##.....##....##.......##....##.....##.########..######..
    // .##.......##.......##...##...##.......#########....##.......#########....##.......##....##.....##.##...##...##......
    // .##....##.##.......##....##..##....##.##.....##....##.......##.....##....##.......##....##.....##.##....##..##......
    // ..######..########.##.....##..######..##.....##....##.......##.....##....##.......##.....#######..##.....##.########

    step1CercaFatture: function(event) {
      let self = this;
      this.step1_cercaFatture_in_progress = true;

      let request_data = {
        api_uid: this.form.api_uid,
        api_key: this.form.api_key,
        anno: this.form.anno_cerca_fatture,
        data_inizio: this.form.data_inizio_cerca_fatture,
        data_fine: this.form.data_fine_cerca_fatture
      };

      let url = "/api/fic/documenti/lista";
      window.axios
        .post(url, request_data, { crossdomain: true })
        .then(function(response) {
          self.step1_cercaFatture_in_progress = false;
          if (response.data) {
            if (response.data.error) {
              return;
            }

            self.lista_documenti = response.data.lista_documenti;

            if (self.lista_documenti.length > 0) {
              self.lista_documenti_selected = self.lista_documenti[0].id;
              self.documento_selezionato = self.lista_documenti[0];
            }
          } else {
          }
        })
        .catch(function(error) {
          self.step1_cercaFatture_in_progress = false;
        });
    },

    step2CercaFattura: function(event) {
      this.dettagli_documento_selezionato = null;
      this.step4_inviaFattura_completed = false;
      for (let i = 0; i < this.lista_documenti.length; i++) {
        if (this.lista_documenti[i].id == this.lista_documenti_selected) {
          this.documento_selezionato = this.lista_documenti[i];
        }
      }
    },

    // .########..########.########.########....###.....######...##.......####....########....###....########.########.##.....##.########.....###...
    // .##.....##.##..........##.......##......##.##...##....##..##........##.....##.........##.##......##.......##....##.....##.##.....##...##.##..
    // .##.....##.##..........##.......##.....##...##..##........##........##.....##........##...##.....##.......##....##.....##.##.....##..##...##.
    // .##.....##.######......##.......##....##.....##.##...####.##........##.....######...##.....##....##.......##....##.....##.########..##.....##
    // .##.....##.##..........##.......##....#########.##....##..##........##.....##.......#########....##.......##....##.....##.##...##...#########
    // .##.....##.##..........##.......##....##.....##.##....##..##........##.....##.......##.....##....##.......##....##.....##.##....##..##.....##
    // .########..########....##.......##....##.....##..######...########.####....##.......##.....##....##.......##.....#######..##.....##.##.....##

    step3DettagliFattura: function(event) {
      let self = this;
      this.step3_dettagliFattura_in_progress = true;

      let request_data = {
        api_uid: this.form.api_uid,
        api_key: this.form.api_key,
        id: this.documento_selezionato.id
      };

      let url = "/api/fic/documenti/dettagli";
      window.axios
        .post(url, request_data, { crossdomain: true })
        .then(function(response) {
          self.step3_dettagliFattura_in_progress = false;
          if (response.data) {
            if (response.data.error) {
              return;
            }

            self.dettagli_documento_selezionato =
              response.data.dettagli_documento;

            // aggiungo codice IVA e codice Conto agli articoli presenti nel documento
            self.dettagli_documento_selezionato.lista_articoli.forEach(function(
              el,
              index
            ) {
              self.cod_iva_options.forEach(el2 => {
                if (el2.text == el.valore_iva + "% " + el.desc_iva) {
                  el.cod_iva = el2.value;
                }
              });
            });
          } else {
          }
        })
        .catch(function(error) {
          self.step3_dettagliFattura_in_progress = false;
        });
    },

    //   .####.##....##.##.....##.####....###.......########....###....########.########.##.....##.########.....###...
    //   ..##..###...##.##.....##..##....##.##......##.........##.##......##.......##....##.....##.##.....##...##.##..
    //   ..##..####..##.##.....##..##...##...##.....##........##...##.....##.......##....##.....##.##.....##..##...##.
    //   ..##..##.##.##.##.....##..##..##.....##....######...##.....##....##.......##....##.....##.########..##.....##
    //   ..##..##..####..##...##...##..#########....##.......#########....##.......##....##.....##.##...##...#########
    //   ..##..##...###...##.##....##..##.....##....##.......##.....##....##.......##....##.....##.##....##..##.....##
    //   .####.##....##....###....####.##.....##....##.......##.....##....##.......##.....#######..##.....##.##.....##

    step4InviaFattura: function() {
      let self = this;
      this.step4_inviaFattura_in_progress = true;

      let fattura = JSON.parse(
        JSON.stringify(this.dettagli_documento_selezionato)
      );

      // controllo i campi che dovrebbero essere null invece che stringa vuota
      if (fattura.PA_esigibilita.length == 0) fattura.PA_esigibilita = null;

      fattura.numero = null;
      // fattura.marca_bollo = null;

      let data_fattura = Vue.moment(fattura.data, "L");

      fattura.lista_pagamenti.forEach(function(el, index) {

        // calcolo la nuova data di scadenza, calcolando la differenza dalla data fattura precedente
        let data_scadenza = Vue.moment(el.data_scadenza, "L");
        let diff_scadenza_from_data_fattura = data_scadenza.diff(data_fattura, "days");
        el.diff_days = diff_scadenza_from_data_fattura;

        el.metodo = "not";
        el.importo = "auto";
      });

      let request_data = {
        api_uid: this.form.api_uid,
        api_key: this.form.api_key,
        invoice_date: this.form.ricorrenza_start_value,
        json_invoice: JSON.stringify(fattura),
        recurring_value: this.form.ricorrenza_value,
        recurring_type: this.form.ricorrenza_type,
        recurring_end_value: this.form.ricorrenza_end_value,
        recurring_end_type: this.form.ricorrenza_end_type
      };

      this.step4_inviaFattura_error = false;
      this.step4_inviaFattura_completed = false;

      let url = "/api/invoices/recurring/save";
      window.axios
        .post(url, request_data, { crossdomain: true })
        .then(function(response) {
          self.step4_inviaFattura_in_progress = false;
          if (response.data) {
            if (response.data.error) {
              self.step4_inviaFattura_error = true;
              return;
            }

            if (response.data.status == "scheduled") {
              self.step4_inviaFattura_completed = true;
              return;
            }

            self.step4_inviaFattura_error = true;
          } else {
            self.step4_inviaFattura_error = true;
          }
        })
        .catch(function(error) {
          self.step4_inviaFattura_in_progress = false;
          self.step4_inviaFattura_error = true;
        });
    }
  },

  computed: {
    validation: function() {
      let self = this;
      return {
        anno_cerca_fatture: function() {
          return (
            self.form.anno_cerca_fatture &&
            self.form.anno_cerca_fatture.length == 4
          );
        },
        data_inizio_cerca_fatture: function() {
          let d = Date.parse(self.form.data_inizio_cerca_fatture);
          return self.form.data_inizio_cerca_fatture && !isNaN(d);
        },
        data_fine_cerca_fatture: function() {
          let d = Date.parse(self.form.data_fine_cerca_fatture);
          return self.form.data_fine_cerca_fatture && !isNaN(d);
        },
        api_key: function() {
          return self.form.api_key && self.form.api_key.length > 10;
        },
        api_uid: function() {
          return self.form.api_uid && self.form.api_uid.length > 3;
        }
      };
    },

    ricorrenzaProssimaFattura: function() {
      if (!this.documento_selezionato || !this.dettagli_documento_selezionato)
        return "-";
      let next = Vue.moment(this.dettagli_documento_selezionato.data, "L");

      if (this.form.ricorrenza_start_value != null)
      {
          next = Vue.moment(this.form.ricorrenza_start_value);
      }
      let today = Vue.moment();
      while (next.diff(today) < 0) {
        next = next.add(this.form.ricorrenza_value, this.form.ricorrenza_type);
      }
      if (this.form.ricorrenza_start_value == null && next)
      {
          this.form.ricorrenza_start_value = next.format("YYYY-MM-DD");
      }
      return next.format("LL");
    }
  }
};
</script>

<style>
</style>
