<template>
    <b-container>
        <h2 class="mt-3">
            <i class="fas fa-ticket-alt"></i> Eventbrite su FattureInCloud.it in 3 semplici passi
            <span class="float-right">
                <guida-wizard-creazione-fatture-eventbrite></guida-wizard-creazione-fatture-eventbrite>
            </span>
        </h2>

        <div class="row justify-content-center mt-5">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header"><i class="fas fa-file-csv"></i> Carica il CSV con tutti gli ordini di Eventbrite, configura i filtri e analizza i dati</div>
                    <div class="card-body">
                        <csv-parsing-eventbrite v-on:data-ready="csvComponentDataReady" v-on:select-row="csvComponentSelectRow"></csv-parsing-eventbrite>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center mt-5" v-if="destinatarioRow">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">Come gestisci le fatture?</div>
                    <div class="card-body">
                        <b-container>
                            <b-row>
                                <b-col>
                                    <b-button v-if="providerFatturazione != 'fic'" block variant="outline-primary" @click="scegliProviderFatturazione('fic')"><i class="fas fa-cloud"></i>&nbsp;FattureInCloud.it</b-button>
                                    <b-button v-if="providerFatturazione == 'fic'" disabled block variant="success"><i class="fas fa-cloud"></i>&nbsp;FattureInCloud.it</b-button>
                                </b-col>
                                <b-col>
                                    <b-button v-if="providerFatturazione != 'xml'" disabled block variant="outline-primary" @click="scegliProviderFatturazione('xml')"><i class="far fa-file-archive"></i>&nbsp;Scarica i file XML</b-button>
                                    <b-button v-if="providerFatturazione == 'xml'" disabled block variant="success"><i class="far fa-file-archive"></i>&nbsp;Scarica i file XML</b-button>
                                </b-col>
                            </b-row>
                        </b-container>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center mt-5" v-show="destinatarioRow && providerFatturazione && providerFatturazione == 'xml'">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">Completa i campi necessari per la fattura elettronica XML</div>
                    <div class="card-body">
                        <template-fattura-xml-eventbrite v-bind:destinatario="destinatarioRow" v-on:data-valid="datiFatturaValidi"></template-fattura-xml-eventbrite>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center mt-5" v-show="destinatarioRow && providerFatturazione && providerFatturazione == 'fic'">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header"><i class="fas fa-cloud"></i> Completa i campi necessari per inviare le fatture su FattureInCloud.it</div>
                    <div class="card-body">
                        <template-fic-eventbrite v-bind:destinatario="destinatarioRow" v-on:data-valid="datiFatturaValidi"></template-fic-eventbrite>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center mt-5" v-show="destinatarioRow && dati_fattura && providerFatturazione && providerFatturazione == 'xml'">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header"><i class="far fa-paper-plane"></i> Invia le fatture</div>
                    <div class="card-body">
                        <download-xml-eventbrite v-bind:destinatari="destinatari" v-bind:dati_fattura="dati_fattura"></download-xml-eventbrite>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center mt-5" v-show="destinatarioRow && dati_fattura && providerFatturazione && providerFatturazione == 'fic'">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header"><i class="far fa-paper-plane"></i> Invia le fatture</div>
                    <div class="card-body">
                        <invia-fic-eventbrite v-bind:destinatari="destinatari" v-bind:dati_fattura="dati_fattura"></invia-fic-eventbrite>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
export default {
    data() {
        return {
            dati_fattura: null,
            destinatari: null,
            destinatarioRow: null,
            providerFatturazione: null,
        };
    },
    mounted: function () {
        if (fep && fep.settings && fep.settings.fic_account_name.length > 0) {
        }
    },
    methods: {
        csvComponentDataReady: function (data) {
            this.destinatari = data;
        },
        csvComponentSelectRow: function (row) {
            if (!row) {
                this.destinatarioRow = null;
                return;
            }

            let rowFattura = {
                ragione_sociale: row.first_name + " " + row.last_name,
                indirizzo: row.fatturazione.indirizzo,
                indirizzo_2: row.fatturazione.indirizzo_2,
                comune: row.fatturazione.citta,
                provincia: row.fatturazione.provincia,
                cap: row.fatturazione.cap,
                nazione: row.fatturazione.nazione,
                valore: row.valore_biglietto,
                iva: row.iva_biglietto,
                causale: row.fatturazione.causale,
                pec: row.pec,
                sdi: row.sdi,
                cf: row.cf,
                piva: row.piva,
            };

            this.destinatarioRow = rowFattura;
        },
        datiFatturaValidi: function (dati_fattura) {
            this.dati_fattura = dati_fattura;
        },
        scegliProviderFatturazione: function (provider) {
            this.providerFatturazione = provider;
        },
    },
};
</script>

<style>
</style>
