<template>
    <b-container>
        <!-- DATI FATTURA -->
        <b-row>
            <b-col>
                <b>Numero fattura</b>
                <br />
                <b-alert variant="success" show> <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;Il numero della fattura seguirà la numerazione di FattureInCloud.it </b-alert>
            </b-col>
            <b-col>
                <b-form-group id="input-group-2" label="Data documento:" label-for="input-2" description="Data della fattura">
                    <b-form-input id="input-2" v-model="form.data_documento" type="date" required :state="validation.data_documento()"></b-form-input>
                    <b-form-invalid-feedback :state="validation.data_documento()">Inserire una data valida</b-form-invalid-feedback>
                    <b-form-valid-feedback :state="validation.data_documento()">Ben fatto.</b-form-valid-feedback>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    label="Causale e articolo fattura:"
                    description="Specificare il nome dell'evento o un riferimento da far comparire all'interno della lista Articoli della fattura e alla fine dell'esempio causale della fattura che vedi di seguito"
                >
                    <b-form-input v-model="form.suffisso_causale" type="text" placeholder="Nome dell'evento"></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group label="Esempio causale:" description="La causale cambia per ogni ordine effettuato e contiene il numero di biglietti acquistati, l'id dell'ordine e il nome specificato sopra">
                    <b-form-input v-model="form.causale_parsed" type="text" readonly></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-alert variant="info" show>
                    <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;L'API UID e l'API Key, sono due codici che identificano in maniera univoca il tuo account su FattureInCloud e consentono a FatturaElettronicaPro di caricare le
                    fatture. Per approfondimenti e video tutorial leggere: [Link all'articolo]
                    <br />
                    <b-button class="mt-2" block variant="outline-info" size="sm" href="https://secure.fattureincloud.it/api" target="_blank">
                        <i class="fas fa-external-link-alt"></i> Vai su FattureInCloud.it e copia i tuoi codici API UID e API Key
                    </b-button>
                </b-alert>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    id="input-group-fic-1"
                    label="API UID:"
                    label-for="input-fic-1"
                    description="E' possibile ottenere il proprio API UID sul sito di FattureInCloud.it nella sezione </> API nella barra laterale"
                    :state="validation.api_uid()"
                >
                    <b-form-input id="input-fic-1" v-model="form.api_uid" type="text" required min="1" placeholder :state="validation.api_uid()" :readonly="test_api"></b-form-input>
                    <b-form-invalid-feedback :state="validation.api_uid()">L'API UID è obbligatorio per poter inviare le fatture</b-form-invalid-feedback>
                    <b-form-valid-feedback :state="validation.api_uid()">Ben fatto.</b-form-valid-feedback>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    id="input-group-fic-2"
                    label="API Key:"
                    label-for="input-fic-2"
                    description="E' possibile ottenere la propria API KEY sul sito di FattureInCloud.it nella sezione </> API nella barra laterale"
                    :state="validation.api_key()"
                >
                    <b-form-input id="input-fic-2" v-model="form.api_key" type="text" required min="10" placeholder :state="validation.api_key()" :readonly="test_api"></b-form-input>
                    <b-form-invalid-feedback :state="validation.api_key()">L'API KEY è obbligatoria per poter inviare le fatture</b-form-invalid-feedback>
                    <b-form-valid-feedback :state="validation.api_key()">Ben fatto.</b-form-valid-feedback>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row class="mb-3">
            <b-col>
                <b-alert variant="info" show>
                    <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;E' necessario effettuare un test dei dati inseriti per programmare correttamente l'invio delle fatture, questo permetterà anche di recuperare i dati per il Codice
                    IVA e il Conto di Saldo da utilizzare in fattura.
                </b-alert>
                <b-alert variant="danger" v-if="test_api_error" show>
                    <i class="fas fa-plug"></i>&nbsp;I dati inseriti come API UIS e API KEY non risultano validi, controllare nuovamente i dati inseriti e riprovare, nel caso in cui il problema non si risolve, contatta il supporto tramite
                    il tasto "Hai bisogno di aiuto?"
                </b-alert>
            </b-col>
        </b-row>

        <b-row class="mb-3 justify-content-center">
            <b-col cols="6">
                <b-button block v-if="!test_api" size="lg" variant="warning" :disabled="!(validation.api_key() && validation.api_uid()) || test_api_inprogress" @click="testApi">
                    <i v-if="!test_api_inprogress" class="fas fa-plug"></i>
                    <b-spinner v-if="test_api_inprogress" small type="grow"></b-spinner>Effettua un test dei dati API UID e API Key
                </b-button>
                <b-button block v-if="test_api" variant="outline-success" @click="resetTestApi"> <i class="fas fa-check-double"></i> Effettua di nuovo il test dei dati API UID e API Key </b-button>
            </b-col>
        </b-row>

        <b-row v-if="test_api">
            <b-col>
                <b-form-group id="input-group-fic-3" label="Codice IVA:" label-for="input-fic-3" description="E' possibile visualizzare tutte le aliquote sul sito di FattureInCloud.it nel menu Impostazioni > Aliquote IVA">
                    <b-form-select id="input-fic-3" v-model="form.cod_iva" :options="cod_iva_options" :state="validation.cod_iva()" :disabled="!(validation.api_key() && validation.api_uid())" required>
                        <template slot="first">
                            <option :value="null" disabled>-- Seleziona il valore IVA con il quale sono stati venduti i biglietti --</option>
                        </template>
                    </b-form-select>
                    <b-form-invalid-feedback :state="validation.cod_iva()">Il codice IVA è obbligatorio per poter inviare le fatture</b-form-invalid-feedback>
                    <b-form-valid-feedback :state="validation.cod_iva()">Ben fatto.</b-form-valid-feedback>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    id="input-group-fic-4"
                    label="Conto di Saldo:"
                    label-for="input-fic-4"
                    description="E' possibile visualizzare tutti i Conti di Saldo sul sito di FattureInCloud.it nel menu Impostazioni > Metodi Pagamento e Conti"
                    :state="validation.conto_saldo()"
                >
                    <b-form-select id="input-fic-4" v-model="form.conto_saldo" :options="conto_saldo_options" :state="validation.conto_saldo()" :disabled="!(validation.api_key() && validation.api_uid())" required>
                        <template slot="first">
                            <option :value="null" disabled>-- Seleziona il Conto di Saldo sul quale registrare i pagamenti --</option>
                        </template>
                    </b-form-select>
                    <b-form-invalid-feedback :state="validation.conto_saldo()">Il Conto di Saldo è obbligatorio per poter inviare le fatture</b-form-invalid-feedback>
                    <b-form-valid-feedback :state="validation.api_uid()">Ben fatto.</b-form-valid-feedback>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row v-if="test_api">
            <b-col>
                <b-form-group
                    id="input-group-fic-5"
                    label="Salva l'anagrafica cliente in FattureInCloud:"
                    label-for="input-fic-5"
                    description="E' possibile salvare le informazioni anagrafiche del cliente, in questo modo è possibile conservare l'indirizzo email per inviare una copia della fattura"
                >
                    <b-form-checkbox id="nput-fic-5" v-model="form.salva_anagrafica" name="nput-fic-5" value="1" unchecked-value="0" switch>Salva l'anagrafica del cliente</b-form-checkbox>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row v-if="test_api">
            <b-col>
                <b-form-group id="input-group-fic-6" label="Indirizzi mancanti:" label-for="input-fic-6" description="E' possibile aggiungere valori XXXXXXX o 00000 per i campi dell'indirizzo mancanti">
                    <b-form-checkbox id="nput-fic-6" v-model="form.indirizzo_mancante_placeholder" name="nput-fic-6" value="1" unchecked-value="0" switch>Riempi i campi dell'indirizzo mancanti con XXXXXXX e 00000</b-form-checkbox>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row v-if="test_api">
            <b-col>
                <b-form-group id="input-group-fic-7" label="Fattura elettronica:" label-for="input-fic-7" description="E' possibile salvare la fatture con il vecchio formato, non elettronico, disabilitando questa voce">
                    <b-form-checkbox id="nput-fic-7" v-model="form.fattura_elettronica_pa" name="nput-fic-7" value="1" unchecked-value="0" switch>Fattura elettronica</b-form-checkbox>
                </b-form-group>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    props: {
        destinatario: Object,
    },
    mounted: function () {
        // Aggiungere la data di oggi
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();

        // today = dd + "/" + mm + "/" + yyyy;
        today = yyyy + "-" + mm + "-" + dd;
        this.form.data_documento = today;

        if (fep && fep.settings) {
            this.form.api_uid = fep.settings.fic_api_uid.length > 0 ? fep.settings.fic_api_uid : null;
            this.form.api_key = fep.settings.fic_api_key.length > 0 ? fep.settings.fic_api_key : null;
        }
    },
    data() {
        return {
            form_validated: false,
            test_api: false,
            test_api_inprogress: false,
            test_api_error: false,
            cod_iva_options: null,
            conto_saldo_options: null,
            form: {
                data_documento: null,
                causale: "",
                causale_parsed: "",
                suffisso_causale: "",
                api_key: null,
                api_uid: null,
                cod_iva: "0",
                conto_saldo: null,
                salva_anagrafica: 0,
                indirizzo_mancante_placeholder: 0,
                fattura_elettronica_pa: 1,
            },
        };
    },
    methods: {
        resetTestApi: function (event) {
            this.test_api = false;
            this.form.cod_iva = "0";
            this.form.conto_saldo = null;
            this.test_api_error = false;
        },
        testApi: function (event) {
            let self = this;
            this.test_api_error = false;
            this.test_api_inprogress = true;

            let request_data = {
                api_uid: this.form.api_uid,
                api_key: this.form.api_key,
                campi: ["nome", "lista_iva", "lista_conti"],
            };

            let url = "/api/invoices/testficapidata";
            window.axios
                .post(url, request_data, { crossdomain: true })
                .then(function (response) {
                    self.test_api_inprogress = false;
                    if (response.data) {
                        if (response.data.error) {
                            self.test_api = false;
                            self.test_api_error = true;
                            return;
                        }

                        if (response.data.lista_iva) {
                            self.cod_iva_options = [];

                            response.data.lista_iva.forEach((element) => {
                                self.cod_iva_options.push({
                                    value: element.cod_iva,
                                    text: element.valore_iva + "% " + element.descrizione_iva,
                                });
                            });
                        }

                        if (response.data.lista_conti) {
                            self.conto_saldo_options = [];

                            response.data.lista_conti.forEach((element) => {
                                self.conto_saldo_options.push({
                                    value: element.nome_conto,
                                    text: element.nome_conto,
                                });
                            });
                        }

                        self.test_api = true;
                    } else {
                        self.test_api = false;
                    }
                })
                .catch(function (error) {
                    self.test_api_inprogress = false;
                    self.test_api = false;
                    self.test_api_error = true;
                });
        },
    },
    computed: {
        validation: function () {
            let self = this;
            return {
                data_documento: function () {
                    let d = Date.parse(self.form.data_documento);
                    return self.form.data_documento && !isNaN(d);
                },
                api_key: function () {
                    return self.form.api_key && self.form.api_key.length > 10;
                },
                api_uid: function () {
                    return self.form.api_uid && self.form.api_uid.length > 3;
                },
                cod_iva: function () {
                    return self.form.cod_iva != null && self.form.cod_iva >= 0;
                },
                conto_saldo: function () {
                    return self.form.conto_saldo != null && self.form.conto_saldo.length > 0;
                },
            };
        },
    },
    watch: {
        destinatario: {
            handler: function (val, oldVal) {
                if (val) {
                    this.form.causale = val.causale;
                    this.form.causale_parsed = this.form.causale + " " + this.form.suffisso_causale;
                }
            },
            deep: true,
        },
        form: {
            handler: function (val, oldVal) {
                this.form.causale_parsed = this.form.causale + " " + this.form.suffisso_causale;

                for (let c in this.validation) {
                    if (!this.validation[c]()) {
                        this.$emit("data-valid", null);
                        this.form_validated = false;
                        return false;
                    }
                }

                this.$emit("data-valid", val);
                this.form_validated = false;
                return true;
            },
            deep: true,
        },
    },
};
</script>

<style>
</style>
