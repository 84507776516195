<template>
  <div class="container">
    <h2 class="mt-3">
      Fatture Eventbrite inviate
      <b-button
        class="float-right"
        :disabled="delete_inprogress || search_inprogress"
        variant="outline-success"
        @click="searchInvoice()"
        size="sm"
      >
        <b-spinner v-if="search_inprogress" small type="grow"></b-spinner>
        <i v-if="!search_inprogress" class="fas fa-sync-alt"></i> Aggiorna la tabella
      </b-button>
    </h2>

    <b-row>
      <b-col>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-0">
            <b-button size="sm" block href="#" v-b-toggle.search_filter variant="light"><i class="fas fa-filter"></i> Filtra i risultati</b-button>
          </b-card-header>
          <b-collapse
            id="search_filter"
            accordion="search-filter-accordion"
          >
            <b-card-body>
              <b-form-checkbox
                id="search_filter_trashed"
                v-model="search_filter.with_trashed"
                name="search_filter_trashed"
                value="true"
                unchecked-value="false"
              >Mostra le fatture cancellate</b-form-checkbox>
            </b-card-body>
          </b-collapse>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <b-button
          block
          :disabled="selected.length == 0 || delete_inprogress || search_inprogress"
          variant="outline-danger"
          @click="deleteInvoice(null)"
        >
          <b-spinner v-if="delete_inprogress" small type="grow"></b-spinner>
          <i v-if="!delete_inprogress" class="fas fa-trash-alt"></i>
          Elimina le {{ selected.length }} fatture selezionate
        </b-button>
        <b-button
          size="sm"
          block
          :disabled="delete_inprogress || search_inprogress"
          variant="light"
          @click="cleanSelectedRow()"
        >
          <i class="fas fa-times"></i> Pulisci le righe selezionate
        </b-button>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <b-table
          ref="selectableTable"
          selectable
          :select-mode="selectMode"
          selected-variant="active"
          :items="items"
          :fields="fields"
          @row-selected="onRowSelected"
          responsive
          sticky-header="100%"
          :no-border-collapse="true"
          :table-variant="(search_inprogress || delete_inprogress) ? 'warning' : 'none'"
        >
          <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>

          <template v-slot:cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <i class="fas fa-check"></i>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>

          <template v-slot:cell(actions)="data">
            <b-button
              v-if="data.item.status == 'sended'"
              size="sm"
              variant="danger"
              :disabled="delete_inprogress || search_inprogress"
              @click="deleteInvoice(data.item)"
            >
              <b-spinner v-if="delete_inprogress" small type="grow"></b-spinner>
              <i v-if="!delete_inprogress" class="fas fa-trash-alt"></i> Elimina
            </b-button>
          </template>

          <template v-slot:cell(status)="data">
            <template v-if="data.item.status == 'sended'">
              <i class="fas fa-paper-plane text-success"></i>
            </template>
            <template v-if="data.item.status == 'scheduled'">
              <i class="fas fa-clock text-info"></i>
            </template>
            <template v-if="data.item.status == 'scheduled_retry'">
              <i class="fas fa-clock text-warning"></i>
            </template>
            <template v-if="data.item.status == 'deleting'">
              <i class="fas fa-trash-alt text-info"></i>
            </template>
            <template v-if="data.item.status == 'deleting_retry'">
              <i class="fas fa-trash-alt text-warning"></i>
            </template>
            <template v-if="data.item.status == 'deleted'">
              <i class="fas fa-trash-alt text-success"></i>
            </template>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        { key: "index", label: "#" },
        { key: "selected", label: "Sel." },
        { key: "created_at", label: "Creata il" },
        "nome",
        "oggetto_visibile",
        { key: "status", label: "Stato" },
        { key: "actions", label: "Azioni" }
      ],
      items: [],
      selectMode: "range",
      selected: [],
      search_inprogress: false,
      delete_inprogress: false,
      total_min_to_complete: 0,
      show_delete_info_popup: false,
      search_filter: {
          with_trashed: false
      }
    };
  },
  mounted: function() {
    this.searchInvoice();
  },
  methods: {
    onRowSelected(items) {
      if (this.search_inprogress || this.delete_inprogress) return;
      this.selected = items;
    },
    searchInvoice: function(callback_success = () => {}) {
      let self = this;
      self.search_inprogress = true;

      this.cleanSelectedRow();

      let request_data = this.search_filter;

      let url = "/api/invoices/search";
      window.axios
        .post(url, request_data, { crossdomain: true })
        .then(function(response) {
          self.search_inprogress = false;

          self.items = response.data;

          callback_success();
        })
        .catch(function(error) {
          self.search_inprogress = false;
        });
    },
    deleteInvoice: function(item) {
      let self = this;
      let request_data = {
        invoices: []
      };
      self.delete_inprogress = true;

      if (item != null) {
        request_data.invoices.push(item.id);
      } else {
        this.selected.forEach(element => {
          request_data.invoices.push(element.id);
        });
      }

      if (request_data.invoices.length == 0) return;

      let url = "/api/invoices/delete";
      window.axios
        .post(url, request_data, { crossdomain: true })
        .then(function(response) {
          setTimeout(function() {
            self.searchInvoice(function() {
              self.delete_inprogress = false;

              self.cleanSelectedRow();

              self.$bvToast.toast(
                `Le ${response.data.counter} fatture saranno eliminate entro ${response.data.total_min_to_complete} minuti`,
                {
                  title: "Fatture eliminate con successo",
                  autoHideDelay: 5000,
                  appendToast: true,
                  variant: "success",
                  solid: true
                }
              );
            });
          }, 5000);
        })
        .catch(function(error) {
          self.delete_inprogress = false;

          self.$bvToast.toast(
            "Prova a ricaricare la pagina e ripetere l'operazione",
            {
              title: "Si è verificato un errore",
              autoHideDelay: 5000,
              appendToast: true,
              variant: "danger",
              solid: true
            }
          );
        });
    },
    cleanSelectedRow: function() {
      this.$refs.selectableTable.clearSelected();
      this.selected = [];
    }
  }
};
</script>

<style>
</style>
