/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)

import {
    ToastPlugin
} from 'bootstrap-vue'
Vue.use(ToastPlugin)

const moment = require('moment');
require('moment/locale/it');
Vue.use(require('vue-moment'), {
    moment
});

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

// Eventbrite
Vue.component('guida-wizard-creazione-fatture-eventbrite', require('./components/GuidaWizardCreazioneFattureEventbrite.vue').default);
Vue.component('wizard-creazione-fatture-eventbrite', require('./components/WizardCreazioneFattureEventbrite.vue').default);
Vue.component('csv-parsing-eventbrite', require('./components/CSVParsingEventbrite.vue').default);
Vue.component('csv-items-table-eventbrite', require('./components/CSVItemsTableEventbrite.vue').default);
Vue.component('template-fattura-xml-eventbrite', require('./components/TemplateFatturaXMLEventbriteComponent.vue').default);
Vue.component('template-fic-eventbrite', require('./components/TemplateFattureInCloudEventbrite.vue').default);
Vue.component('download-xml-eventbrite', require('./components/DownloadXMLEventbrite.vue').default);
Vue.component('invia-fic-eventbrite', require('./components/InviaFattureInCloudEventbrite.vue').default);

// Scheduler
Vue.component('wizard-creazione-fatture-ricorrenti', require('./components/WizardCreazioneFattureRicorrenti.vue').default);
Vue.component('guida-wizard-creazione-fatture-ricorrenti', require('./components/GuidaWizardCreazioneFattureRicorrenti.vue').default);

// Lista fatture
Vue.component('invoice-list', require('./components/InvoiceList.vue').default);
Vue.component('scheduler-list', require('./components/SchedulerList.vue').default);

Vue.component(
    'passport-clients',
    require('./components/passport/Clients.vue').default
);

Vue.component(
    'passport-authorized-clients',
    require('./components/passport/AuthorizedClients.vue').default
);

Vue.component(
    'passport-personal-access-tokens',
    require('./components/passport/PersonalAccessTokens.vue').default
);

Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY hh:mm')
    }
});

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    data: {
        test: "test string"
    },
    methods: {
        callback: function (input1) {
            console.log(input1);
        }
    },
});
