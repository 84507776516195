<template>
  <b-container>
    <b-button size="sm" variant="outline-info" @click="mostraGuida">
      <i class="far fa-life-ring"></i> Guida
    </b-button>

    <b-modal id="bv-guida" size="xl" scrollable centered title-html="<i class='far fa-life-ring'></i> Come funziona?" button-size="sm" hide-footer>

      <p>
        Prima di iniziare ricorda che il sistema ti guiderà passo passo fino alla fine, puoi riaprire questa guida in qualsiasi momento e puoi anche chiedere aiuto usando il tasto in basso a destra dello schermo.
        <br />
        <br />Leggi tutti gli avvisi
        <i class="fas fa-exclamation-triangle text-danger"></i> e suggerimenti
        <i class="fa fa-info-circle text-info" aria-hidden="true"></i> che troverai, contengono tante informazioni e suggerimenti utili per sfruttare al meglio FatturaElettronicaPro.
      </p>

      <hr class="my-3" />

      <h5 class="my-3">
        <i class="fas fa-file-csv"></i> Carica e analizza i dati di Eventbrite
      </h5>
      <p>
        Scarica il file giusto con i biglietti venduti da Eventbrite, ATTENZIONE Eventbrite fornisce diversi report, bisogna scaricare quello corretto, segui con attenzione questa guida se non lo hai già fatto:
        <a
          href="https://www.fatturaelettronicapro.it/come-scaricare-la-lista-di-tutti-i-partecipanti-ad-un-evento-da-eventbrite/"
          target="_blank"
        >Come scaricare la lista di tutti i partecipanti ad un evento da Eventbrite</a>.
      </p>
      <p>
        Dopo aver caricato il file, è importante scegliere le COLONNE con le informazioni aggiuntive che hai richiesto al momento della registrazione all'evento, segui questa quida se non sai di cosa sto parlando:
        <a
          href="https://www.fatturaelettronicapro.it/come-configurare-eventbrite-per-la-fattura-elettronica/"
          target="_blank"
        >Come configurare Eventbrite per la fattura elettronica</a>, il sistema proverà ad identificarle automaticamente, ma è meglio dargli un'occhiata.
      </p>
      <!-- <p>
          Procedendo con l'elaborazione dei dati, il sistema automaticamente tirerà fuori due insiemi, uno con tutte le fatture pronte per essere inviate, l'altro con tutte quelle contenenti degli errori.
          <br />Questi errori possono essere ignorati andando ad attivare dei "Filtri e impostazioni per elaborare i dati" in maniera differente, ad esempio: è possibile includere tutti i biglietti gratuiti dell'evento (Approfondimento: XXXXXXXX);
          oppure è possibile includere quei biglietti con Codice Fiscale e Partita IVA mancante (Approfondimento: XXXXXXXX) e tanti altri.
          <br />
      </p>-->

      <h5 class="my-3">
        <i class="fas fa-cloud"></i> FattureInCloud
      </h5>
      <p>Per poter caricare le fatture su FattureInCloud hai bisogno dell'API UID e l'API Key, ma non spaventarti se non sai cosa sono, quando serviranno c'è un bottone che ti porta direttamente sul sito di FattureInCloud per copiarli e incollarli.</p>

      <h5 class="my-3">
        <i class="far fa-paper-plane"></i> Invia le fatture
      </h5>
      <p>Abbiamo finito! Le fatture sono pronte per essere inviate, a causa dei limiti di utilizzo delle API imposti da FattureInCloud, è possibile caricare 30 fatture al minuto e 500 all'ora, FatturaElettronicaPro si occuperà di inviarle un po' alla volta e ti dirà in quanto tempo riuscira ad inviare tutte le fatture senza far arrabbiare FattureInCloud.</p>

      <b-button
        v-if="!ho_capito"
        class="mt-3 mb-0"
        size="lg"
        block
        variant="success"
        @click="hoCapito"
      >Ok, ho letto tutto, andiamo avanti!</b-button>
      <b-button
        v-if="ho_capito"
        class="mt-3 mb-0"
        size="lg"
        block
        variant="info"
        @click="hoCapito"
      >Ok, chiudi la guida!</b-button>
    </b-modal>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      ho_capito: false,
      mostra_guida: false
    };
  },
  mounted: function() {
    if (fep && fep.settings && fep.settings.fic_account_name.length > 0) {
      this.hoCapito();
    }
  },
  methods: {
    hoCapito: function(event) {
      this.ho_capito = true;
      this.mostra_guida = false;
      this.$bvModal.hide("bv-guida");
    },
    mostraGuida: function(event) {
      this.mostra_guida = true;
      this.$bvModal.show("bv-guida");
    }
  }
};
</script>

<style>
</style>
