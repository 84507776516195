<template>
  <div>
    <b-pagination
      v-model="currentPage"
      :total-rows="total_rows"
      :per-page="perPage"
      aria-controls="table-csv"
      size="sm"
      align="right"
    ></b-pagination>
    <b-table
      id="table-csv"
      striped
      hover
      :items="table"
      :fields="table_fields"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template v-slot:cell(index)="data">{{ (data.index + 1) + ((currentPage - 1) * perPage) }}</template>

      <template v-slot:cell(name)="data">{{ data.item.nome }}</template>

      <template v-slot:cell(quantita_biglietto)="data">
        <i class="fas fa-ticket-alt"></i>
        &nbsp;{{ data.item.quantita_biglietto }} ({{ data.item.valore_biglietto }}€)
      </template>

      <template v-slot:cell(actions)="data">
        <b-button
          v-if="data.detailsShowing"
          size="sm"
          @click="data.toggleDetails"
          variant="outline-primary"
        >
          <i class="fas fa-window-close"></i>&nbsp;Chiudi
        </b-button>
        <b-button
          v-if="!data.detailsShowing"
          size="sm"
          @click="data.toggleDetails"
          variant="outline-primary"
        >
          <i class="fas fa-clipboard-list"></i>&nbsp;Mostra dati
        </b-button>
      </template>

      <template v-slot:cell(errors)="data">
        <i class="fas fa-exclamation-triangle" v-if="data.item.errori_fatturazione.length > 0"></i>
        <i class="fas fa-check text-success" v-if="data.item.errori_fatturazione.length == 0"></i>
      </template>

      <template slot="row-details" slot-scope="row">
        <b-card>
          <b-row>
            <b-col>
              <i class="fas fa-file-invoice-dollar"></i>
              {{ row.item.nome }}
              <br />
              <b>Codice Fiscale:</b>
              {{ row.item.fatturazione.cf }}
              <br />
              <b>Partita IVA:</b>
              {{ row.item.fatturazione.piva }}
              <span
                v-if="row.item.fatturazione.indirizzo"
              >
                <br />
                <br />
                <b>Indirizzo</b>
                <br />
                <small>
                  {{ row.item.fatturazione.indirizzo }}
                  <br />
                  {{ row.item.fatturazione.indirizzo_2 }}
                  <br v-if="row.item.fatturazione.indirizzo_2" />
                  {{ row.item.fatturazione.citta }} ({{ row.item.fatturazione.provincia }}), {{ row.item.fatturazione.cap }} ({{ row.item.fatturazione.nazione }})
                  <br />
                  <span v-if="row.item.fatturazione.info">Info: {{ row.item.fatturazione.info }}</span>
                  <br v-if="row.item.fatturazione.info" />
                </small>
              </span>
            </b-col>
            <b-col>
              {{ row.item.quantita_biglietto }}
              <i class="fas fa-ticket-alt"></i>
              {{ row.item.valore_biglietto }}€
              <br />
              <small>
                IVA {{ row.item.iva_biglietto }}€
                <br />
                {{ row.item.order_type }}
                <br />
                {{ row.item.ticket_type }}
              </small>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <b-alert
                v-for="err in row.item.errori_fatturazione"
                :key="err.id_order"
                show
                variant="danger"
              >
                <i class="fas fa-exclamation-triangle"></i>
                &nbsp;{{ err.msg }}
              </b-alert>
              <b-alert v-for="err in row.item.warning" :key="err.id_order" show variant="warning">
                <i class="fas fa-exclamation-triangle"></i>
                &nbsp;{{ err.msg }}
              </b-alert>
            </b-col>
          </b-row>
          <!-- <b-row class="mt-3">
                <b-col>
                  <b-button v-on:click="selectRow(row)">Seleziona</b-button>
                </b-col>
          </b-row>-->
        </b-card>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="total_rows"
      :per-page="perPage"
      aria-controls="table-csv"
      size="sm"
      align="right"
    ></b-pagination>
  </div>
</template>

<script>
export default {
  props: {
      table: Array,
  },
  mounted() {},
  data() {
    return {
      table_fields: [
        { key: "index", label: "#" },
        "id_order",
        { key: "name", label: "Full Name" },
        "email",
        { key: "quantita_biglietto", label: "Biglietti" },
        "actions",
        { key: "errors", label: "" }
      ],
      perPage: 15,
      currentPage: 1
    };
  },
  methods: {
    selectRow: function(row) {
      this.$emit("select-row", row.item);
    }
  },
  watch: {},
  computed: {
    total_rows() {
      return this.table.length;
    }
  }
};
</script>
